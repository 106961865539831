import axios from "axios";
import { Participate } from "../interfaces/entities/Participate";

const URL = "http://localhost:3333/participates/";

export class ParticipateService {

    getParticipate = (participateId: string): Promise<Participate | any> => {
        return axios.get(`${URL}${participateId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    getParticipateBySessionIdAndParticipantId = (sessionId: string, participantId: string): Promise<Participate | any> => {
        return axios.get(`${URL}?sessionId=${sessionId}&participantId=${participantId}`).then(
            res => {
                return res.data.length > 0 ? res.data[0] : null;
            }
        ).catch(err => {
            return err;
        })
    }

    getAllParticipates = (): Promise<Participate | any> => {
        return axios.get(`${URL}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    createParticipate = (sessionId: string, participantId: string): Promise<Participate | any> => {

        let participate: Participate = {
            id: "",
            participantId,
            sessionId,
            dateParticipate: new Date(),
            participantGiftReceiverId: null
        }

        return axios.post(URL, participate).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    getParticipatesByCreator = (sessionId: string, participantId: string): Promise<Array<Participate> | any> => {
        return axios.get(`${URL}?sessionId=${sessionId}&participantId=${participantId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    updateParticipate = (participate: Participate): Promise<Participate | any> => {
        return axios.put(`${URL}${participate.id}`, participate).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    deleteParticipate = (participateId: string): Promise<string | any> => {
        return axios.delete(`${URL}${participateId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }
}