import React, { useEffect, useState } from 'react';
import { Participate } from '../../interfaces/entities/Participate';
import { ParticipateService } from '../../services/participate-service';

const ParticipateCRUD = () => {
    const participateService: ParticipateService = new ParticipateService();

    const [participatesList, setParticipatesList] = useState<Participate[]>([]);
    const [sessionId, setSessionId] = useState<string>("");
    const [participantId, setParticipantId] = useState<string>("");

    useEffect(() => {

        updateParticipatesList();

    }, []);

    const updateParticipatesList = () => {

        participateService.getAllParticipates().then(res => {
            setParticipatesList(res);
        })
    }

    const createParticipate = () => {
        participateService.createParticipate(sessionId, participantId).then(res => {
            console.log(res);
            updateParticipatesList();
        })
    }

    const updateParticipantGiftReceiverIdState = (participate: Participate, value: string) => {
        const newState = participatesList.map(obj => {
            if (obj.id === participate.id) {
                return { ...participate, participantGiftReceiverId: value };
            }

            // 👇️ otherwise return object as is
            return obj;
        });

        setParticipatesList(newState);
    };

    const updateParticipate = (participate: Participate) => {
        participateService.updateParticipate(participate).then(res => {
            updateParticipatesList();
        })
    }

    const deleteParticipate = (participate: Participate) => {
        participateService.deleteParticipate(participate.id).then(res => {
            updateParticipatesList();
        })
    }

    return (
        <div>
            <div>
                <label htmlFor="sessionId">Session Id</label>
                <input type="text" id="sessionId" value={sessionId} onChange={(event) => setSessionId(event.target.value)} />
                <label htmlFor="participantId">Password</label>
                <input type="text" id="participantId" value={participantId} onChange={(event) => setParticipantId(event.target.value)} />
                <button onClick={createParticipate}>Create</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Session ID</th>
                        <th>Participate ID</th>
                        <th>Date creation</th>
                        <th>Gift receiver ID</th>
                        <th>Validate</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {participatesList.map((p: Participate, key: number) => {
                        return (
                            <tr key={key}>
                                <th>{p.id}</th>
                                <th>
                                    {p.sessionId}
                                </th>
                                <th>
                                    {p.participantId}
                                </th>
                                <th>
                                    {p.dateParticipate.toString()}
                                </th>
                                <th>
                                    <input type="text" value={p.participantGiftReceiverId ? p.participantGiftReceiverId : ""}
                                        onChange={(event) => updateParticipantGiftReceiverIdState(p, event.target.value)} />
                                </th>
                                <th>
                                    <button onClick={() => updateParticipate(p)}>Validate</button>
                                </th>
                                <th>
                                    <button onClick={() => deleteParticipate(p)}>Delete</button>
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ParticipateCRUD;