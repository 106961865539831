import React from 'react';

const SessionsList = () => {
    return (
        <div className='sessions-list'>
            <div className="field">
                <label htmlFor="">Rechercher une session</label>
                <div className="block-input">
                    <input type="text" />
                    <button className="icon">Search</button>
                </div>
            </div>
            <div className="table">
                <div className="theader">
                    <div className="line">
                        <div className="th w-6">Nom de la session</div>
                        <div className="th w-4">Etat</div>
                        <div className="th w-2">Rejoindre</div>
                    </div>
                </div>
                <div className="tbody">
                    <div className="line">
                        <div className="tb w-6">
                            Le nom de la session 
                        </div>
                        <div className="tb w-4">
                            Crée
                        </div>
                        <div className="tb w-2">
                            Check
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionsList;