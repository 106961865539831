import { RouteCustom } from "./interfaces/RouteCustom";
import ParticipantCRUD from "./views/CRUD/ParticipantCRUD";
import SearchSession from "./views/SearchSession";
import SessionCRUD from "./views/CRUD/SessionCRUD";
import SessionsList from "./views/SessionsList";
import ParticipateCRUD from "./views/CRUD/ParticipateCRUD";
import DrawSessionGeneral from "./views/DrawSessionGeneral";
import ConnectionTMP from "./views/ConnectionTMP";
import WhoIGift from "./views/WhoIGift";

var dashRoutes: Array<RouteCustom> = [
  /*{
    path: "/authentication",
    name: "Authentication",
    icon: "users_single-02",
    component: <Questions />,
    layout: "/auth",
  },*/
  {
    path: "/session-search",
    name: "Recherche de session",
    icon: "",
    component: <SessionsList />,
    layout: "/santa",
    under_path: null
  },
  {
    path: "/participantCRUD",
    name: "Gestion des participants",
    icon: "",
    component: <ParticipantCRUD />,
    layout: "/santa",
    under_path: null
  },
  {
    path: "/sessionCRUD",
    name: "Gestion des sessions",
    icon: "",
    component: <SessionCRUD />,
    layout: "/santa",
    under_path: null
  },
  {
    path: "/participateCRUD",
    name: "Gestion des participate",
    icon: "",
    component: <ParticipateCRUD />,
    layout: "/santa",
    under_path: null
  },
  {
    path: "/draw-session-general",
    name: "Tire au sort la session",
    icon: "",
    component: <DrawSessionGeneral />,
    layout: "/santa",
    under_path: null
  },
  {
    path: "/connection-tmp",
    name: "Connection temporaire",
    icon: "",
    component: <ConnectionTMP />,
    layout: "/santa",
    under_path: null
  },
  {
    path: "/who-I-gift",
    name: "A qui offre-je le cadeau",
    icon: "",
    component: <WhoIGift />,
    layout: "/santa",
    under_path: null
  },
  /*{
    path: null,
    name: "Questions",
    icon: "",
    component: null,
    layout: "/admin/question&/admin/categories&/admin/questions-list",
    under_path: [{
      path: "/question",
      name: "Question",
      icon: "",
      component: <Question />,
      layout: "/admin",
      under_path: null
    },
    {
      path: "/categories",
      name: "Categories",
      icon: "",
      component: <Categories />,
      layout: "/admin",
      under_path: null
    },
    {
      path: "/questions-list",
      name: "Liste des questions",
      icon: "",
      component: <QuestionsList />,
      layout: "/admin",
      under_path: null
    }]
  },
  {
    path: null,
    name: "Tests",
    icon: "",
    component: null,
    layout: "/test",
    under_path: [{
      path: "/test",
      name: "Test",
      icon: "",
      component: <Question />,
      layout: "/admin",
      under_path: null
    }]
  }*/
];
export default dashRoutes;
