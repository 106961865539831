import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ParticipantService } from '../services/participant-service';

const ConnectionTMP = () => {
    const [nameParticipant, setNameParticipant] = useState<string>("");
    const [passwordParticipant, setPasswordParticipant] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const participantService: ParticipantService = new ParticipantService();
    const navigate = useNavigate();
    const connectionParticipant = () => {
        participantService.getParticipantByNameAndPassword(nameParticipant, passwordParticipant).then(res => {
            if (res === null) {
                setError("Mauvais couple d'identifiants");
            } else {
                localStorage.setItem("user", JSON.stringify(res));
                navigate("/santa/who-I-gift");
            }
        })
    }

    return (
        <div className='connection-tmp'>
            <div className="form">
                <div className="field">
                    <label htmlFor="nameParticipant">Name</label>
                    <input type="text" id="nameParticipant" value={nameParticipant} onChange={(event) => setNameParticipant(event.target.value)} />

                </div>
                <div className="field">
                    <label htmlFor="passwordParticipant">Password</label>
                    <input type="text" id="passwordParticipant" value={passwordParticipant} onChange={(event) => setPasswordParticipant(event.target.value)} />

                </div>
                <div className="field">

                    <button onClick={connectionParticipant}>Se connecter</button>
                    {error &&
                        <div>Erreur : {error}</div>}
                </div>
            </div>
        </div>
    );
};



export default ConnectionTMP;