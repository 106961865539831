import axios from "axios";
import { Participant } from "../interfaces/entities/Participant";

const URL = "http://localhost:3333/participants/";

export class ParticipantService {

    getParticipant = (participantId: string): Promise<Participant | any> => {
        return axios.get(`${URL}${participantId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    getParticipantByNameAndPassword = (participantName: string, participantPassword: string): Promise<Participant | any> => {
        return axios.get(`${URL}?participantName=${participantName}&participantPassword=${participantPassword}`).then(
            res => {
                return res.data.length > 0 ? res.data[0] : null;
            }
        ).catch(err => {
            return err;
        })
    }

    getAllParticipants = (): Promise<Participant[] | any> => {
        return axios.get(`${URL}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    createParticipant = (participantName: string, participantPassword: string): Promise<Participant | any> => {

        let participant: Participant = {
            id: "",
            participantName,
            participantPassword
        }

        return axios.post(URL, participant).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    getParticipantsByCreator = (sessionId: string, participantId: string): Promise<Array<Participant> | any> => {
        return axios.get(`${URL}?sessionId=${sessionId}&participantId=${participantId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    updateParticipant = (participant: Participant): Promise<Participant | any> => {
        return axios.put(`${URL}${participant.id}`, participant).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    deleteParticipant = (participantId: string): Promise<string | any> => {
        return axios.delete(`${URL}${participantId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }
}