import React, { useEffect, useState } from 'react';
import { Session } from '../../interfaces/entities/Session';
import { SessionService } from '../../services/session-service';

const SessionCRUD = () => {
    const sessionService: SessionService = new SessionService();

    const [sessionsList, setSessionsList] = useState<Session[]>([]);
    const [nameNewSession, setNameNewSession] = useState<string>("");
    const [creatorSessionId, setCreatorSessionId] = useState<string>("");

    useEffect(() => {

        updateSessionsList();

    }, []);

    const updateSessionsList = () => {

        sessionService.getAllSessions().then(res => {
            setSessionsList(res);
        })
    }

    const createSession = () => {
        sessionService.createSession(nameNewSession, creatorSessionId).then(res => {
            console.log(res);
            updateSessionsList();
        })
    }

    const updatesessionListNameState = (session: Session, value: string) => {
        const newState = sessionsList.map(obj => {
            if (obj.id === session.id) {
                return { ...session, participantName: value };
            }

            // 👇️ otherwise return object as is
            return obj;
        });

        setSessionsList(newState);
    };


    const updateParticipant = (session: Session) => {
        sessionService.updateSession(session).then(res => {
            updateSessionsList();
        })
    }

    const deleteParticipant = (session: Session) => {
        sessionService.deleteSession(session.id).then(res => {
            updateSessionsList();
        })
    }

    return (
        <div>
            <div>
                <label htmlFor="nameNewParticipant">Name</label>
                <input type="text" id="nameNewParticipant" value={nameNewSession} onChange={(event) => setNameNewSession(event.target.value)} />
                <label htmlFor="passwordNewParticipant">CREATOR ID</label>
                <input type="text" id="passwordNewParticipant" value={creatorSessionId} onChange={(event) => setCreatorSessionId(event.target.value)} />
                <button onClick={createSession}>Create</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>STATE</th>
                        <th>CREATOR ID</th>
                        <th>Validate</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {sessionsList.map((s: Session, key: number) => {
                        return (
                            <tr key={key}>
                                <th>{s.id}</th>
                                <th>
                                    <input type="text" value={s.sessionName}
                                        onChange={(event) => updatesessionListNameState(s, event.target.value)}
                                    />
                                </th>
                                <th>
                                    {s.sessionState}
                                </th>
                                <th>
                                    {s.participantId}
                                </th>
                                <th>
                                    <button onClick={() => updateParticipant(s)}>Validate</button>
                                </th>
                                <th>
                                    <button onClick={() => deleteParticipant(s)}>Delete</button>
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default SessionCRUD;