import { Session } from "../interfaces/entities/Session";
import axios from "axios";

const URL = "http://localhost:3333/sessions/";

export class SessionService {


    getSession = (sessionId: string): Promise<Session | any> => {
        return axios.get(`${URL}${sessionId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }


    getAllSessions = (): Promise<Session[] | any> => {
        return axios.get(`${URL}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    createSession = (sessionName: string, participantId: string): Promise<Session | any> => {

        let session: Session = {
            id: "",
            sessionName,
            sessionState: 1,
            participantId
        }

        return axios.post(URL, session).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    getSessionsByCreator = (participantId: string): Promise<Array<Session> | any> => {
        return axios.get(`${URL}?participantId=${participantId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    updateSession = (session: Session): Promise<Session | any> => {
        return axios.put(`${URL}${session.id}`, session).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }

    deleteSession = (sessionId: string): Promise<string | any> => {
        return axios.delete(`${URL}${sessionId}`).then(
            res => {
                return res.data;
            }
        ).catch(err => {
            return err;
        })
    }
}