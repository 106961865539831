import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Authentication from './layouts/Authentication';
import Santa from './layouts/Santa';

const Loader = () => (
  <div>
    <div>Loading</div>
  </div>
);


function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="santa/*" element={<Santa />} />
          <Route path="auth/*" element={<Authentication />} />
          <Route path="*" element={<Navigate to="/santa/*" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
