import React, { useEffect, useState } from 'react';
import { Participant } from '../interfaces/entities/Participant';
import { Participate } from '../interfaces/entities/Participate';
import { drawParticipants } from '../methods/draw';
import { ParticipantService } from '../services/participant-service';
import { ParticipateService } from '../services/participate-service';

const DrawSessionGeneral = () => {

    const [participatesList, setParticipatesList] = useState<Participate[]>([]);
    const participateService: ParticipateService = new ParticipateService();
    useEffect(() => {
        participateService.getAllParticipates().then(res => {
            setParticipatesList(res);
        })
    }, []);

    const draw = () => {
        console.log(participatesList)
        updateParticipatesList(drawParticipants(participatesList));
    }

    const updateParticipatesList = (participatesListParam: Participate[]) => {
        participatesListParam.map(p => {
            participateService.updateParticipate(p)
        })
    }

    return (
        <div>
            <button onClick={draw}>DRAW</button>
        </div>


    );
};

export default DrawSessionGeneral;