import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Participant } from '../interfaces/entities/Participant';
import { Participate } from '../interfaces/entities/Participate';
import { ParticipantService } from '../services/participant-service';
import { ParticipateService } from '../services/participate-service';

const WhoIGift = () => {

    const navigate = useNavigate();
    const [participant, setParticipant] = useState<Participant>();
    const [participantGifted, setParticipantGifted] = useState<Participant>();
    const [participate, setParticipate] = useState<Participate>();

    const participateService: ParticipateService = new ParticipateService();
    const participantService: ParticipantService = new ParticipantService();

    useEffect(() => {
        if (localStorage.user === undefined) {
            navigate("/santa/connection-tmp");
        } else {
            let p: Participant = JSON.parse(localStorage.user);
            console.log(p)
            setParticipant(JSON.parse(localStorage.user));
            participateService.getParticipateBySessionIdAndParticipantId("1", p.id).then(parts => {
                console.log(parts)
                setParticipate(parts);

                participantService.getParticipant(parts.participantGiftReceiverId).then(pGifted => {
                    console.log(pGifted)
                    setParticipantGifted(pGifted);
                })
            })
        }
    }, []);

    return (
        <div className='who-I-gift'>
            <div className="container">
                <div className="name-gifted">
                    {participantGifted?.participantName}
                </div>
                <div className="label">
                    Offre lui un cadeau !
                </div>
            </div>
        </div>
    );
};

export default WhoIGift;