import React from 'react';

const Authentication = () => {
    return (
        <div>
            Auth
        </div>
    );
};

export default Authentication;