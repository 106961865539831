import { Participate } from "../interfaces/entities/Participate";

/**
 * Choose who will be the person to give a gift per participant
 * @param participateList of the session
 * @returns 
 */
export const drawParticipants = (participateList: Array<Participate>): Array<Participate> => {
    
    const shuffledArray: Array<Participate> = participateList.sort((a, b) => 0.5 - Math.random());

    let lastParticipate = shuffledArray.length - 1;

    for(let i = 0; i<lastParticipate; i++){
        shuffledArray[i].participantGiftReceiverId = shuffledArray[i+1].participantId;
    }

    shuffledArray[lastParticipate].participantGiftReceiverId = shuffledArray[0].participantId;

    return shuffledArray;
}