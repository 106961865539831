import React, { useEffect, useState } from 'react';
import { Participant } from '../../interfaces/entities/Participant';
import { ParticipantService } from '../../services/participant-service';

const ParticipantCRUD = () => {

    const participantService: ParticipantService = new ParticipantService();

    const [participantsList, setParticipantsList] = useState<Participant[]>([]);
    const [nameNewParticipant, setNameNewParticipant] = useState<string>("");
    const [passwordNewParticipant, setPasswordNewParticipant] = useState<string>("");

    useEffect(() => {

        updateParticipantsList();

    }, []);

    const updateParticipantsList = () => {

        participantService.getAllParticipants().then(res => {
            setParticipantsList(res);
        })
    }

    const createParticipant = () => {
        participantService.createParticipant(nameNewParticipant, passwordNewParticipant).then(res => {
            console.log(res);
            updateParticipantsList();
        })
    }

    const updateParticipantListNameState = (participant: Participant, value: string) => {
        const newState = participantsList.map(obj => {
            if (obj.id === participant.id) {
                return { ...participant, participantName: value };
            }

            // 👇️ otherwise return object as is
            return obj;
        });

        setParticipantsList(newState);
    };

    const updateParticipantListPasswordState = (participant: Participant, value: string) => {
        const newState = participantsList.map(obj => {
            if (obj.id === participant.id) {
                return { ...participant, participantPassword: value };
            }

            // 👇️ otherwise return object as is
            return obj;
        });

        setParticipantsList(newState);
    };

    const updateParticipant = (participant: Participant) => {
        participantService.updateParticipant(participant).then(res => {
            updateParticipantsList();
        })
    }

    const deleteParticipant = (participant: Participant) => {
        participantService.deleteParticipant(participant.id).then(res => {
            updateParticipantsList();
        })
    }

    return (
        <div>
            <div>
                <label htmlFor="nameNewParticipant">Name</label>
                <input type="text" id="nameNewParticipant" value={nameNewParticipant} onChange={(event) => setNameNewParticipant(event.target.value)} />
                <label htmlFor="passwordNewParticipant">Password</label>
                <input type="text" id="passwordNewParticipant" value={passwordNewParticipant} onChange={(event) => setPasswordNewParticipant(event.target.value)} />
                <button onClick={createParticipant}>Create</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>PASSWORD</th>
                        <th>Validate</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {participantsList.map((p: Participant, key: number) => {
                        return (
                            <tr key={key}>
                                <th>{p.id}</th>
                                <th>
                                    <input type="text" value={p.participantName}
                                        onChange={(event) => updateParticipantListNameState(p, event.target.value)}
                                    />
                                </th>
                                <th>
                                    <input type="text" value={p.participantPassword} 
                                        onChange={(event) => updateParticipantListPasswordState(p, event.target.value)}/>
                                </th>
                                <th>
                                    <button onClick={() => updateParticipant(p)}>Validate</button>
                                </th>
                                <th>
                                    <button onClick={() => deleteParticipant(p)}>Delete</button>
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};


export default ParticipantCRUD;