import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { RouteCustom } from '../interfaces/RouteCustom';
import routes from '../routes';
import { defaultPath } from '../variables/general';
import SearchSession from '../views/SearchSession';
import Authentication from './Authentication';

const Santa = () => {
    const navigate = useNavigate();

    const [routesWorked, setRoutesWorked] = useState<Array<any>>([])

    useEffect(() => {

        /*if (localStorage.user === undefined) {
            navigate('/auth/*');
        }*/

        let routesTmp: Array<any> = []

        routes.forEach((prop: RouteCustom) => {

            console.log(prop)

            if (prop.path !== null) {
                routesTmp.push(
                    <Route
                        path={prop.path}
                        element={prop.component}
                        key={prop.path}
                    />
                );
            } else {
                if (prop.under_path !== null) {
                    prop.under_path.forEach((prop_under: RouteCustom) => {
                        if (prop_under.path !== null) {
                            routesTmp.push(
                                <Route
                                    path={prop_under.path}
                                    element={prop_under.component}
                                    key={prop_under.path}
                                />
                            );
                        }
                    });
                }
            }
        });

        routesTmp.push(<Route path="/*" key={"defaut:" + defaultPath} element={<Navigate to={defaultPath} />} />)

        setRoutesWorked(routesTmp);

    }, []);

    return (
        <Routes>
            {
                routesWorked.map((route: any) => {
                    return (route);
                })
            }
        </Routes>
    );
};

export default Santa;